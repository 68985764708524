<template>
  <b-container class="my-5">
    <b-row>
      <b-col>
        <h1>Taiga</h1>
      </b-col>
    </b-row>
    <LoadingSpinner :is-loaded="isLoaded">
      <b-table
        striped
        hover
        :items="projects"
        :fields="[
          { key: 'name', label: 'Name' },
          { key: 'id', label: 'Action' },
        ]"
      >
        <template v-slot:cell(id)="data">
          <b-button variant="primary" @click="sendInvitation(data.value)">
            Send invitation
          </b-button>
        </template>
      </b-table>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import { apiTaiga, LoadingSpinner } from "frontend-common";

export default {
  name: "Taiga",
  components: { LoadingSpinner },
  mixins: [apiTaiga],
  props: {},
  data() {
    return {
      isLoaded: false,
      projects: [],
    };
  },
  computed: {},
  mounted() {
    this.apiTaigaProjects()
      .then((response) => {
        this.projects = response.data;
      })
      .finally(() => (this.isLoaded = true));
  },
  methods: {
    sendInvitation(projectId) {
      this.apiTaigaInviteMyself(projectId)
        .then(() =>
          this.$bvToast.toast("Invitation sent", {
            title: "Success",
            variant: "success",
          }),
        )
        .catch(() =>
          this.$bvToast.toast("Failed to send invitation", {
            title: "Error",
            variant: "danger",
          }),
        );
    },
  },
};
</script>

<style scoped></style>
